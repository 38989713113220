import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

export default function Completed() {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl =
    nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;

  const [challenge, setChallenge] = useState([]);
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [searchRoomCode, setSearchRoomCode] = useState("");

  const fetchChallenges = async () => {
    const access_token = localStorage.getItem("token");
    const headers = { Authorization: `Bearer ${access_token}` };

    try {
      const res = await axios.get(
        `${baseUrl}challange/completed_challange?page=${pageNumber}&_limit=${limit}`,
        { headers }
      );
      setChallenge(res.data.status);
      setNumberOfPages(res.data.totalPages);
    } catch (error) {
      console.error("Error fetching challenges:", error);
    }
  };

  const handlePageClick = (data) => {
    setPageNumber(data.selected + 1);
  };

  const dateFormat = (e) => {
    const date = new Date(e);
    return date.toLocaleString("default", {
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const filteredChallenges = searchRoomCode
    ? challenge.filter(
        (game) =>
          game.Room_code &&
          game.Room_code.toString().includes(searchRoomCode.toString())
      )
    : challenge;

  useEffect(() => {
    fetchChallenges();
  }, [pageNumber, limit]);

  return (
    <div className="container mt-5">
      <div className="card shadow-lg">
        <div className="card-body" style={{ backgroundColor: "white" }}>
          <h4 className="card-title text-center text-primary">
            Completed Challenges
          </h4>

<div className="d-flex justify-content-between align-items-center mb-4">
  <input
    type="text"
    className="form-control col-md-4"
    placeholder="Search by Room Code"
    value={searchRoomCode}
    onChange={(e) => setSearchRoomCode(e.target.value)}
  />
  <select
    className="form-control col-md-2"
    onChange={(e) => setLimit(Number(e.target.value))}
  >
    <option value="10">10</option>
    <option value="20">20</option>
    <option value="50">50</option>
    <option value="100">100</option>
    <option value="5000">5000</option>
  </select>
</div>

          {/* Table */}
          <div className="table-responsive">
            <table className="table table-striped table-bordered">
              <thead>
                <tr className="bg-primary text-white">
                  <th>#</th>
                  <th>Room Code</th>
                  <th>Creator</th>
                  <th>Accepter</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Game Type</th>
                  <th>Winner</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filteredChallenges.map((game, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {game.Room_code === 0 ? (
                        <span style={{ color: "orange", fontWeight: "bold" }}>
                          Waiting
                        </span>
                      ) : (
                        <span style={{ color: "green", fontWeight: "bold" }}>
                          {game.Room_code}
                        </span>
                      )}
                    </td>
                    <td>{game.Created_by?.Name}</td>
                    <td>{game.Accepetd_By?.Name || "N/A"}</td>
                    <td>{game.Game_Ammount}</td>
                    <td className="text-primary font-weight-bold">
                      {game.Status}
                    </td>
                    <td>{game.Game_type}</td>
                    <td>{game.Winner?.Name || "N/A"}</td>
                    <td>{dateFormat(game.createdAt)}</td>
                    <td>
                      <Link
                        className="btn btn-sm btn-primary"
                        to={`/view/${game._id}`}
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={numberOfPages}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center mt-3"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </div>
  );
}